.tb-0 {
  padding: 0px 20px;
  height: 74vh;
}
.MuiDataGrid-columnSeparator {
  display: none !important;
}
.tb-0 .MuiDataGrid-columnHeaderTitle {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #1d2026;
}
.tb-0 .MuiDataGrid-cellContent {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #1d2026;
  word-break: break-word;
}

.tb-0 .MuiDataGrid-columnHeader:focus,
.css-1usso8h-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
  color: #1d2026;
}

.MuiTablePagination-toolbar {
  color: #1d2026;
}
.cell-padding {
  padding-top: 1.41vh !important;
  padding-bottom: 1.6vh !important;
}
.MuiFormLabel-root.Mui-required .MuiFormLabel-asterisk {
  color: red;
}
.cell-padding.MuiDataGrid-cell:focus {
  outline: none;
}
.textSlice {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}
.MuiTooltip-tooltip {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  color: #5c5f67;
  background: #ffffff;
  border: 1px solid #3f96dd;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.MuiTooltip-arrow::before {
  border: 1px solid #3f96dd;
  background-color: #fff;
}
.MuiTooltip-tooltip.MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementTop {
  margin-bottom: 8px;
}
.cell-padding span {
  width: 100%;
}
