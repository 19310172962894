@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: blue-gray-900;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.expandAnimationBottom {
  color: #fff;
  animation: fadeInBottom 0.3s ease-in-out;
  -webkit-animation: fadeInBottom 0.3s ease-in-out;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    -o-transform: translateY(-50px);
  }
  to {
    opacity: 1;
  }
}
